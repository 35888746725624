<template>
    <div class="generic">
      <Headline>Legal</Headline>

      <!-- <b-col lg="2" md="3" cols="4" class="mx-auto">
        <img class="maintenance-icon" src="../assets/icons/maintenance.png"/>
      </b-col> -->

      <ContentBox headline="General Information">
        <p>
          This site is maintained by the Chair for Theory of Science and Technology
          of RWTH Aachen University:
        </p>
        <p>
          RWTH Aachen <br/>
          Human Technology Institute <br/>
          Chair for Philosophy of Science and Technology <br/>
          4th Floor, Room 404 <br/>
          Theaterplatz 14 <br/>
          52062 Aachen
        </p>
        <p>Contact us via mail or via email to
          <a href="mailto:csslab@humtec.rwth-aachen.de">csslab@humtec.rwth-aachen.de</a>,
          or see the
          <router-link :to="{ name: 'contact'}">contact page</router-link>
          for more information.</p>
        <p>Responsibility for the design, programmming and technical maintenance of this site:
          <ul>
            <li><router-link :to="{ name: 'heyen'}">David Heyen</router-link></li>
            <li><router-link :to="{ name: 'kerksieck'}">Frederic Kerksieck</router-link></li>
          </ul>
        </p>
        <p>Responsibility for the content of this site:
          <ul>
            <li><router-link :to="{ name: 'kasprowicz'}">Dawid Kasprowicz</router-link></li>
            <li><router-link :to="{ name: 'schuettler'}">Lisa Schüttler</router-link></li>
            </ul>
          </p>
        <p>This site uses cookies. See our
          <router-link :to="{ name: 'privacy'}">Privacy &amp; Cookie Policy</router-link>
          for more information.</p>
        <p>
          This site is built with
          <a href="https://www.vuejs.org" target="_blank" rel="noopener">Vue.js</a>, along with
          several plugins und addons. In order for it to function properly,
          JavaScript is needed. Additionaly, due to its client-side nature,
          the Vue.js framework may temporarily store neccessary information in
          your computer's cache.</p>
        <p>
          This page is part of the RWTH Aachen University network.
          As such, RWTH Aachen University's Legal statement applies.
          <a href="https://www.rwth-aachen.de/cms/root/Footer/Services/~bw/Impressum/?lidx=1"
          target="_blank" rel="noopener">
            See its Legal statement here.
          </a>
        </p>
      </ContentBox>

      <ContentBox headline="3rd Party Content">
        <p>
          The original x-ray image of a laptop has been kindly provided
          by Eugene Mah. It is licensed under
          <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
          target="_blank" rel="noopener">
            CC-BY-NC-SA 4.0
          </a>.
          Find the uncolorized original here:
          <a href="https://v16.imablog.net/2013/10/laptop_x-ray/"
          target="_blank" rel="noopener">
            https://v16.imablog.net/2013/10/laptop_x-ray.
          </a>
      </p>
        <p>
          The RWTH Navigator embedded and/or linked in the Contact section of
          this page is property of RWTH  Aachen University. We are not associated
          with its development or maintenance. If you choose to use it, a special
          Privacy Policy must be accepted due to it being built ontop of Google
          Maps. A prompt will be shown on your first interaction with the RWTH
          Navigator. If you make a choice during this prompt, a cookie will be
          stored on your computer. For more information,
          <a href="https://www.rwth-aachen.de/cms/root/Footer/Services/~cesv/Disclaimer/?lidx=1"
          target="_blank" rel="noopener">
            see RWTH Aachen University's Privacy Policy.
        </a>
      </p>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'LegalPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
  },
  methods: {
    cookieConsentUpdated() {
      this.$emit('cookieConsentUpdated');
    },
  },
};
</script>
<style scoped>
.generic {
  margin-bottom: 50px;
}

.maintenance-icon {
  width: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.privacy-embed {
  width: 100%;
  height: 960px;
}
</style>
