<template>
    <div class="generic">
      <Headline>{{ teachingGovtec.headLine }}</Headline>

      <BlueBox v-bind:headline="teachingGovtec.blueBox.headLine" img="">
        {{ teachingGovtec.blueBox.content }}
      </BlueBox>

      <ContentBox v-bind:headline="teachingGovtec.box1.headLine">
        <ul class="arrow-style">
          <li><p>
            <b>{{ teachingGovtec.box1.content.box1.headLine }}</b><br/>
            <ul class="arrow-style">
              {{ teachingGovtec.box1.content.box1.content[0] }}<br/>
              {{ teachingGovtec.box1.content.box1.content[1] }}<br/>
              {{ teachingGovtec.box1.content.box1.content[2] }}<br/>
            </ul>
          </p></li>
          <li><p>
            <b>{{ teachingGovtec.box1.content.box2.headLine }}</b><br/>
            <ul class="arrow-style second-level">
              {{ teachingGovtec.box1.content.box2.content[0] }}<br/>
              {{ teachingGovtec.box1.content.box2.content[1] }}<br/>
              {{ teachingGovtec.box1.content.box2.content[2] }}<br/>
            </ul>
          </p></li>
          <li><p>
            <b>{{ teachingGovtec.box1.content.box3.headLine }}</b><br/>
            <ul class="arrow-style">
              {{ teachingGovtec.box1.content.box3.content[0] }}
              <ul class="arrow-style second-level">
                <li>{{ teachingGovtec.box1.content.box3.content[1].box1 }}</li>
                <li>{{ teachingGovtec.box1.content.box3.content[1].box2 }}</li>
                <li>{{ teachingGovtec.box1.content.box3.content[1].box3 }}</li>
                <li>{{ teachingGovtec.box1.content.box3.content[1].box4 }}</li>
                <li>{{ teachingGovtec.box1.content.box3.content[1].box5 }}</li>
              </ul>
            </ul>
          </p></li>
          <li><p>
            <b>{{ teachingGovtec.box1.content.box4.headLine }}</b>
            <ul class="arrow-style">
              {{ teachingGovtec.box1.content.box4.content[0] }}
              <ul class="arrow-style second-level">
               <li>{{ teachingGovtec.box1.content.box4.content[1].box1 }}</li>
               <li>{{ teachingGovtec.box1.content.box4.content[1].box2 }}</li>
               <li>{{ teachingGovtec.box1.content.box4.content[1].box3 }}</li>
             </ul>
           </ul>
         </p></li>
         <li><p>
           <b>{{ teachingGovtec.box1.content.box5.headLine }}</b>
           <ul class="arrow-style">
             {{ teachingGovtec.box1.content.box5.content[0] }}
             <ul class="arrow-style second-level">
               <li>{{ teachingGovtec.box1.content.box5.content[1].box1 }}</li>
               <li>{{ teachingGovtec.box1.content.box5.content[1].box2 }}</li>
               <li>{{ teachingGovtec.box1.content.box5.content[1].box3 }}</li>
              </ul>
            </ul>
         </p></li>
        </ul>
      </ContentBox>

      <ContentBox v-bind:headline="teachingGovtec.box2.headLine">
        <ul class="arrow-style">
          <li>{{ teachingGovtec.box2.content[0].desc }}
            <router-link :to="`${teachingGovtec.box2.content[0].router}`">Contact.</router-link></li>
          <li>{{ teachingGovtec.box2.content[1].desc }}
            <a v-bind:href="teachingGovtec.box2.content[1].href"
            target="_blank" rel="noopener">Contact.</a></li>
          <li>{{ teachingGovtec.box2.content[2].desc }}
            <a v-bind:href="teachingGovtec.box2.content[2].href">Contact.</a></li>
        </ul>
      </ContentBox>

      <ContentBox v-bind:headline="teachingGovtec.box3.headLine">
        See <a href="https://www.rwth-aachen.de/cms/root/Studium/Vor-dem-Studium/Studiengaenge/Liste-Aktuelle-Studiengaenge/Studiengangbeschreibung/~sglp/Geschichtswissenschaft-Geschichte-als-/?lidx=1" target="_blank" rel="noopener"> RWTH Aachen University's page</a> for more information.
        <br>
        Or find out more about GovTec on its own website: <br>
        <a href="http://govtec.rwth-aachen.de" target="_blank" rel="noopener"> <Button :to="null" style="margin-top: 10px;"/></a>
      </ContentBox>
    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'GovTecPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
    Button,
  },
  computed: {
    teachingGovtec: function() {
      return this.$store.state.data.teachingGovtec;
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/teachingGovtec.json",
      dir: "teachingGovtec"
    });
  },
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}

.second-level li:before{
  display: inline-block;
  top: 0;
  left: -0.5em;
}

</style>
