<template>
    <div class="generic">
      <Headline>Privacy</Headline>

      <!-- <b-col lg="2" md="3" cols="4" class="mx-auto">
        <img class="maintenance-icon" src="../assets/icons/maintenance.png"/>
      </b-col> -->

      <ContentBox headline="Cookies">
        <p>
          Cookies are small files that a site or its service provider transfers
          to your computer’s hard drive through your Web browser (if you allow).
          These cookies enable the site to recognize your browser and, in case the site
          offers some form of registration, associate you with your registered account.
          We offer no such registration and thus only use cookies to ensure your best
          experience on this site.
          <a href="https://cookies.insites.com" target="_blank">Learn more about cookies.</a>
        </p>
        <p>
          By default, i.e. unless you specifically opt-in, cookies are disabled on this site.
          However, your choice regarding the allowance of cookies may be stored
          on your computer as a cookie itself.
        </p>
        <p>
          You may at any point give or withdraw your consent regarding cookies by clicking
          the Reset button below and selecting an option in the appearing overlay
          at the bottom of this site.
        </p>
        <p>
          You chose to
          <span v-if="this.$store.state.cookieConsent"
          style="color: rgb(189, 205,0);">
            enable
          </span>
          <span v-else style="color: rgb(0, 84, 159);">
            disable
          </span>
          Cookies.
        </p>
        <button @click="cookieConsentUpdated">Reset</button>
      </ContentBox>

      <ContentBox headline="Privacy">
        <p>
          This page is part of the RWTH Aachen University network.
          As such, RWTH Aachen University's Privacy Policy applies.
          <a href="http://www.rwth-aachen.de/cms/root/Footer/Services/~cesv/Disclaimer/?lidx=1" target="_blank">
            See its Privacy Policy here.
          </a>
        </p>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'PrivacyPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
  },
  methods: {
    cookieConsentUpdated() {
      this.$emit('cookieConsentUpdated');
    },
  },
};
</script>
<style scoped>
.generic {
  margin-bottom: 50px;
}

.maintenance-icon {
  width: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.privacy-embed {
  width: 100%;
  height: 960px;
}
</style>
