<template>
    <div class="generic">
      <Headline>Contact</Headline>

      <ContentBox headline="Reach Us">
        <ul class="arrow-style">
          <li>
            <p class="blue">via Email</p>
            <p>
              For general requests please contact us at
              <a href="mailto:csslab@humtec.rwth-aachen.de">csslab@humtec.rwth-aachen.de.</a>
              If you wish to contact a staff member directly, please find the
              corresponding Email adress in the
              <router-link :to="{ name: 'team-overview'}">team section</router-link>
              or the <router-link :to="{ name: 'team-assistants'}">
                student assistants section.
              </router-link>
            </p>
          </li>
          <li>
            <p class="blue">via phone</p>
            <p>
              You can reach out to the Chair's secretary, Mrs. Gibbels, at
              <a href="tel:+492418025496">+49 241 80 25496</a>
              (Mon-Thu 10:00-14:00, Fri 10:00-13:00). If you wish to contact a
              staff member directly, please find the corresponding number in the
            <router-link :to="{ name: 'team-overview'}">team section</router-link>
            (office hours may vary).
            </p>
          </li>
          <li>
            <p class="blue">via mail</p>
            <p>Please find our address below.</p>
          </li>
        </ul>
      </ContentBox>

      <ContentBox headline="Find Us">
        <p>
          RWTH Aachen <br/>
          Human Technology Institute <br/>
          Chair for Philosophy of Science and Technology <br/>
          4th Floor, Room 404 <br/>
          Theaterplatz 14 <br/>
          52062 Aachen
        </p>
        <div class="iframecontainer">
            <iframe title="Lageplan" class="locator"
            src="//maps.rwth-aachen.de/navigator/?lang=en&amp;type=roadmap
            &amp;expandmenu=0&amp;obj=6070"
            name="IFRAME Lageplan">
              &lt;p&gt;Your browser does not support iframes.&lt;/p&gt;
            </iframe>
          </div>
          <p>
            <a
            href="//maps.rwth-aachen.de/navigator/?lang=en&amp;type=roadmap
            &amp;expandmenu=1&amp;obj=6070"
            target="_blank" class="external">Show in RWTH Navigator</a>
          </p>

      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'ContactPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
  },
};
</script>

<style scoped>
.generic {
  margin-bottom: 50px;
}

.blue {
  color: rgb(0, 84, 159);
  margin-bottom: 0px;
}

.locator {
  width: 100%;
  height: 450px;
  border: 1px solid black;
  margin: 0px ;
  overflow-y: auto;
}

@media screen and (max-width: 992px) {
  .locator {
    display: none;
  }
}
</style>
