<template>
    <div class="generic">
        <Headline>{{ teachingColloquia.headLine }}</Headline>

        <BlueBox v-bind:headline="teachingColloquia.content.blueBox.headLine" img="">
            {{ teachingColloquia.content.blueBox.content[0] }}
            <br /><br />
            {{ teachingColloquia.content.blueBox.content[1] }}
        </BlueBox>

        <ContentBox headline="Summer Term 2024">
            <b-row class="event-row" v-for="entry in events('S24')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Winter Term 2023/2024">
            <b-row class="event-row" v-for="entry in events('W24')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Summer Term 2023">
            <b-row class="event-row" v-for="entry in events('S23')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Winter Term 2022/2023">
            <b-row class="event-row" v-for="entry in events('W23')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Summer Term 2022">
            <b-row class="event-row" v-for="entry in events('S22')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Winter Term 2021/2022">
            <b-row class="event-row" v-for="entry in events('W22')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Summer Term 2021">
            <b-row class="event-row" v-for="entry in events('S21')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Winter Term 2020/2021">
            <b-row class="event-row" v-for="entry in events('W21')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Summer Term 2020">
            <b-row class="event-row" v-for="entry in events('S20')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Winter Term 2019/2020">
            <b-row class="event-row" v-for="entry in events('W20')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Summer Term 2019">
            <b-row class="event-row" v-for="entry in events('S19')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Winter Term 2018/2019">
            <b-row class="event-row" v-for="entry in events('W18')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>
        <ContentBox headline="Summer Term 2018">
            <b-row class="event-row" v-for="entry in events('S18')" :key="entry.name + entry.date">
                <b-col md="2" cols="12" class="event-type-column">{{cropDate(entry.date, 0)}} <br>&nbsp&nbsp{{cropDate(entry.date, 1)}}</b-col>
                <b-col md="10" class="event-name-column">{{entry.name}}</b-col>
            </b-row>
        </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import BlueBox from '@/components/BlueBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'ColloqiuaPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    BlueBox,
    Headline,
  },
  methods: {
    events(selector) {
      return this.$store.state.data.colloquiaEvents.filter(entry => entry.semester.includes(selector));
    },
    cropDate(date, index) {
      //return date.substring(index, date.indexOf(' '));
      var arr = date.split(/ (.*)/);
      return arr[index];
    }
  },
  computed: {
    teachingColloquia: function() {
      return this.$store.state.data.teachingColloquia;
    }
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/colloquiaEvents.json",
      dir: "colloquiaEvents"
    });
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/teachingColloquia.json",
      dir: "teachingColloquia"
    });
  },
};
</script>

<style scoped>
.generic {
  padding-bottom: 50px;
}
.event-row {
  margin-left: 0px;
}
.event-name-column {
  padding-bottom: 5px;
  padding-left: 0px;
}
.event-type-column {
  padding-right: 0px;
}
.event-type-column:before {
  content: "";
  border-color: transparent rgb(0, 84, 159);
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: inline-block;
  left: -0.5em;
  position: relative;
}
@media screen and (max-width: 768px) {
  .event-name-column {
    padding-bottom: 15px;
    margin-left: 1.5em;
  }
}
</style>
