<template>
  <div class="home">

      <Banner/>

      <b-row lg="8" class="home-boxes">

        <b-col cols="10" lg="4" class="home-box mx-auto mr-lg-5">
          <div class="col-12 mx-auto home-box-image-wrapper">
            <img class="home-box-image mx-auto" src="../assets/icons/research.png" alt=""/>
          </div>
          <div>
            <h3 class="home-box-heading">{{ home.box1.headLine }}</h3>
            <p>
              {{ home.box1.content }}
            </p>
            <div class="col-12 text-center">
              <Button :to="{name: 'research-overview'}"/>
            </div>
          </div>
        </b-col>

        <b-col cols="10" lg="4" class="home-box mx-auto ml-lg-5">
          <div class="col-12 mx-auto home-box-image-wrapper">
            <img class="home-box-image spinning mx-auto" src="../assets/icons/tools.png" alt=""/>
          </div>
          <h3 class="home-box-heading">{{ home.box2.headLine }}</h3>
          <p>
            {{ home.box2.content }}
          </p>
          <div class="col-12 text-center">
            <Button :to="{name: 'tools-overview'}"/>
          </div>
        </b-col>

      </b-row>

    <div class="home-background">

      <Headline>{{ home.bottom.headLine }}</Headline>

      <ContentBox v-bind:headline="home.bottom.content.box1.headLine">
        <p>
          {{ home.bottom.content.box1.content }}
        </p>
      </ContentBox>

      <ContentBox v-bind:headline="home.bottom.content.box2.headLine">
        <p>
          {{ home.bottom.content.box2.content }}
        </p>
      </ContentBox>

      <ContentBox v-bind:headline="home.bottom.content.box3.headLine">
        <p>
          {{ home.bottom.content.box3.content }}
        </p>
      </ContentBox>

    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import Headline from '@/components/Headline.vue';
import ContentBox from '@/components/ContentBox.vue';
import Button from '@/components/Button.vue';


export default {
  name: 'Home',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    Banner,
    Headline,
    ContentBox,
    Button,
  },
  computed: {
    home: function() {
      return this.$store.state.data.home;
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/home.json",
      dir: "home"
    });
  },
};
</script>

<style>

.home {
  background-color: white;
}

.home-background {
  background-color: whitesmoke;
  padding-bottom: 50px;
}

.home-boxes {
  /*avoid white gap on the right side of the page when using container and rows. */
    margin-right: 0px !important;
    padding-right: 0px !important;
    margin-top: -75px;
}

.home-box {
  /*background-color: white;*/
  margin-bottom: 50px;
  /* BLOCKSTRUKTUR */
  /*
  background-color: rgb(0, 84, 159);
  color: white;
  -webkit-box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.1);
  */
}

.home-box p {
  text-align: center;
}
.home-box-heading {
  text-align: center;
  font-weight: lighter;
  font-size: 20pt;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
  color: rgb(0, 84, 159);
}

.home-box-image-wrapper {
  /*border-radius: 50%;*/
  /*margin-top: -85px; */
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.4));
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-box-image {
  height: 120px;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.spinning:hover {
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@media only screen and (max-width: 992px) {
  .home-boxes {
    margin-top: -100px;
  }
  .home-box {
    margin-top: 40px;
  }
}
</style>
