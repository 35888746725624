<template>
    <b-col cols="12" class="banner">
        <div class="banner-text">
          <h1>{{ bannerHeadLine }}</h1>
          <h2>
            Computational Science Studies Lab <br/>
            Chair of Theory of Science and Technology <br/>
            RWTH Aachen University
          </h2>
        </div>
    </b-col>
</template>

<script>
export default {
  name: 'Banner',
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/homeBanner.json",
      dir: "homeBanner"
    });
  },
  computed: {
    bannerHeadLine: function() {
      return this.$store.state.data.homeBanner.bannerHeadLine;
    },
    subHeadLine1: function() {
      return this.$store.state.data.homeBanner.subHeadLine1;
    },
    subHeadLine2: function() {
      return this.$store.state.data.homeBanner.subHeadLine2;
    }
  }
};
</script>

<style>
.banner {
  height: 400px;
  color: white;
  margin-left: 0!important;
  margin-right: 0!important;
  padding-left: 0!important;
  padding-right: 0!important;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.banner-text {
  width: 100%;
  background-color: rgba(0,0,0,0.2);
}

.banner-text h1 {
  text-shadow: 0px 0px 40px black;
  text-align: center;
  font-size: 60px;
  font-weight: lighter;
}

.banner-text h2 {
  text-shadow: 0px 0px 40px black;
  text-align: center;
  font-size: 25px;
  font-weight: lighter;
}

@media (min-width: 600px) {
  .banner {
    background-image: url(../assets/banner_grey/banner_1280.jpg);
    background-blend-mode: overlay;
    background-color: rgb(0, 84, 159);
  }
}
@media screen and (max-width: 600px) {
  .banner {
    background-image: url(../assets/banner_grey/banner_600.jpg);
    background-blend-mode: overlay;
    background-color: rgb(0, 84, 159);
  }
}
@media print {
  .banner {
    height: initial;
    padding-top: 30px;
    margin-bottom: -15px;
  }
}
</style>
