<template>
    <div class="overview">
        <Banner />

        <Headline>
            {{ teachingOverview.headLine }}
        </Headline>

        <ContentBox v-bind:headline="teachingOverview.content.box1.headLine" color="whitesmoke">
            <p>
                {{ teachingOverview.content.box1.content }}
            </p>
            <Button :to="{ name: teachingOverview.content.box1.link }" />
        </ContentBox>

        <ContentBox v-bind:headline="teachingOverview.content.box2.headLine" color="whitesmoke">
            <p>
                {{ teachingOverview.content.box2.content }}
            </p>
            <Button :to="{ name: teachingOverview.content.box2.link }" />
        </ContentBox>

        <ContentBox v-bind:headline="teachingOverview.content.box3.headLine" color="whitesmoke">
            <p>
                {{ teachingOverview.content.box3.content }}
            </p>
            <Button :to="{ name: teachingOverview.content.box3.link }" />
        </ContentBox>

        <ContentBox v-bind:headline="teachingOverview.content.box4.headLine" color="whitesmoke">
            <p>
                {{ teachingOverview.content.box4.content }}
            </p>
            <Button :to="{ name: teachingOverview.content.box4.link }" />
        </ContentBox>


    </div>
</template>

<script>
import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';
import Banner from '@/components/Banner.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'OverviewPage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
    Button,
    Banner,
  },
  computed: {
    teachingOverview: function() {
      return this.$store.state.data.teachingOverview;
    },
  },
  created() {
    this.$store.dispatch('fetchData', {
      url: "https://raw.githubusercontent.com/DocPaintfull/csslab-web-content/master/data/teachingOverview.json",
      dir: "teachingOverview"
    });
  },
};
</script>

<style>
.overview {
  background-color: whitesmoke;
  padding-bottom: 50px;
}
</style>
