<template>
    <div class="error-page background-global">
      <Headline>We're sorry...</Headline>

      <b-col lg="2" md="3" cols="4" class="mx-auto">
        <img class="maintenance-icon" src="../../assets/icons/maintenance.png"/>
      </b-col>

      <ContentBox headline="">
        <p class="error">
          This page's content is currently under maintenance. It will be back up shortly!
        </p>
      </ContentBox>

    </div>
</template>

<script>

import ContentBox from '@/components/ContentBox.vue';
import Headline from '@/components/Headline.vue';

export default {
  name: 'MaintenancePage',
  props: {
    // will be set by router
    metaProp: Object,
  },
  // vue-head meta info
  metaInfo() {
    return this.metaProp;
  },
  components: {
    ContentBox,
    Headline,
  },
};
</script>
<style scoped>
.error-page {
  height: 500px;
  margin-bottom: 50px;
}
.error {
  text-align: center;
}
.maintenance-icon {
  width: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
