/* CSS Imports */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'nprogress/nprogress.css';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

/* Vue PlugIn Imports */
import BootstrapVue from 'bootstrap-vue';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
// import '@babel/polyfill'
// import './plugins/vuetify';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

/* Vue PlugIn Registrations */
Vue.use(BootstrapVue);
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

/* Additional Vue Settings */
Vue.config.productionTip = false; // disables vue generated tips on build

/* Vue Mount */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
